import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const ViewScore = () => {
    const location = useLocation();
    const { selectedAnswers } = location.state;
    const [quizzes, setQuizzes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [score, setScore] = useState(0);

    useEffect(() => {
        const fetchQuizzes = async () => {
            try {
                const response = await axios.get('http://127.0.0.1:8000/api/quizzes');
                setQuizzes(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching quizzes:', error);
                setLoading(false);
            }
        };

        fetchQuizzes();
    }, []);

    useEffect(() => {
        // Ensure quizzes and selectedAnswers are ready before calculating score
        if (quizzes.length > 0 && Object.keys(selectedAnswers).length > 0) {
            let calculatedScore = 0;
            quizzes.forEach(quiz => {
                const quizIdString = quiz.id.toString();
                if (selectedAnswers.hasOwnProperty(quizIdString) && selectedAnswers[quizIdString] === quiz.correct_answer) {
                    calculatedScore++;
                }
            });
            setScore(calculatedScore);
        }
    }, [quizzes, selectedAnswers]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="card mx-auto mt-5" style={{ maxWidth: '600px' }}>
            <div className="card-body">
                <h1 className="card-title text-center mb-4">Quiz Results</h1>
                <p>Score: {score}/{quizzes.length}</p>
                <hr />
                <h3>Selected Answers:</h3>
                <ul>
                    {quizzes.map(quiz => (
                        <li key={quiz.id}>
                            Question {quiz.id}: {selectedAnswers[quiz.id.toString()]}
                        </li>
                    ))}
                </ul>
                <hr />
                <h3>Correct Answers:</h3>
                <ul>
                    {quizzes.map(quiz => (
                        <li key={quiz.id}>
                            Question {quiz.id}: {quiz.correct_answer}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ViewScore;
