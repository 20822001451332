import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Quiz = () => {
    const [quizzes, setQuizzes] = useState([]);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchQuizzes = async () => {
            try {
                const response = await axios.get('http://127.0.0.1:8000/api/quizzes');
                setQuizzes(response.data);
                
                // Initialize selectedAnswers state with default values
                const initialSelectedAnswers = {};
                response.data.forEach(quiz => {
                    initialSelectedAnswers[quiz.id] = ''; // Initialize each quiz with an empty string
                });
                setSelectedAnswers(initialSelectedAnswers);
            } catch (error) {
                console.error('Error fetching quizzes:', error);
            }
        };

        fetchQuizzes();
    }, []);

    const handleOptionChange = (quizId, selectedOption) => {
        setSelectedAnswers(prevState => ({
            ...prevState,
            [quizId]: selectedOption,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);
    };

    useEffect(() => {
        if (submitted) {
            // Redirect to ViewScore component with selectedAnswers
            navigate('/viewscores', { state: { selectedAnswers } });
        }
    }, [submitted, navigate, selectedAnswers]);

    return (
        <div className="card mx-auto mt-5" style={{ maxWidth: '600px' }}>
            <div className="card-body">
                <h1 className="card-title text-center mb-4">Quiz List</h1>
                <form onSubmit={handleSubmit}>
                    {quizzes.map((quiz, index) => (
                        <div key={quiz.id}>
                            <p>{`${index + 1}. ${quiz.question}`}</p>
                            <label className="d-block">
                                <input
                                    type="radio"
                                    name={`quiz_${quiz.id}`}
                                    value={quiz.option_1}
                                    checked={selectedAnswers[quiz.id] === quiz.option_1}
                                    onChange={() => handleOptionChange(quiz.id, quiz.option_1)}
                                />
                                {quiz.option_1}
                            </label>
                            <label className="d-block">
                                <input
                                    type="radio"
                                    name={`quiz_${quiz.id}`}
                                    value={quiz.option_2}
                                    checked={selectedAnswers[quiz.id] === quiz.option_2}
                                    onChange={() => handleOptionChange(quiz.id, quiz.option_2)}
                                />
                                {quiz.option_2}
                            </label>
                            <label className="d-block">
                                <input
                                    type="radio"
                                    name={`quiz_${quiz.id}`}
                                    value={quiz.option_3}
                                    checked={selectedAnswers[quiz.id] === quiz.option_3}
                                    onChange={() => handleOptionChange(quiz.id, quiz.option_3)}
                                />
                                {quiz.option_3}
                            </label>
                            <label className="d-block">
                                <input
                                    type="radio"
                                    name={`quiz_${quiz.id}`}
                                    value={quiz.option_4}
                                    checked={selectedAnswers[quiz.id] === quiz.option_4}
                                    onChange={() => handleOptionChange(quiz.id, quiz.option_4)}
                                />
                                {quiz.option_4}
                            </label>
                            <hr />
                        </div>
                    ))}
                    <button type="submit" className="btn btn-primary mt-3">Submit Answers</button>
                </form>
            </div>
        </div>
    );
};

export default Quiz;
