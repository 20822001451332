
import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'http://localhost:8000/api', // Adjust based on your API base URL
    headers: {
        'X-Requested-With': 'XMLHttpRequest'
    },
});

axiosInstance.interceptors.request.use(config => {
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    if (token) {
        config.headers['X-CSRF-TOKEN'] = token;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

export default axiosInstance;
