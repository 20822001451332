import React from 'react';
import { Link } from 'react-router-dom';
import './Dashboard.css';

const Dashboard = () => {
  return (
    <div className="dashboard">
      <div className="sidebar">
        <h2>Sidebar</h2>
        <ul>
          <li><Link to="/dashboard">Dashboard</Link></li>
          <li><Link to="/courses">Course</Link></li>
          <li><Link to="/quizzes">Quiz</Link></li>
          <li><Link to="/viewscores">View Score</Link></li>
        </ul>
      </div>
      <div className="main-content">
        <h1>Dashboard</h1>
        <p>Welcome to the dashboard!</p>
      </div>
    </div>
  );
};

export default Dashboard;
