import { createContext, useContext, useState } from 'react';
import axiosInstance from '../axios'; 

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    const fetchCsrfToken = async () => {
        await axiosInstance.get('/sanctum/csrf-cookie');
    };

    const register = async (data) => {
        try {
            // Fetch CSRF token
            await fetchCsrfToken();

            // Send register request
            const response = await axiosInstance.post('/api/register', data);
            setUser(response.data.user);
        } catch (error) {
            if (error.response) {
                console.error('Registration error:', error.response.data);
            } else if (error.request) {
                console.error('No response received:', error.request);
            } else {
                console.error('Error setting up request:', error.message);
            }
        }
    };

    const login = async (data) => {
        try {
            // Fetch CSRF token
            await fetchCsrfToken();

            const response = await axiosInstance.post('/api/login', data);
            setUser(response.data.user);
        } catch (error) {
            if (error.response) {
                console.error('Login error:', error.response.data);
            } else if (error.request) {
                console.error('No response received:', error.request);
            } else {
                console.error('Error setting up request:', error.message);
            }
        }
    };

    const logout = async () => {
        try {
            // Fetch CSRF token
            await fetchCsrfToken();

            await axiosInstance.post('/api/logout');
            setUser(null);
        } catch (error) {
            if (error.response) {
                console.error('Logout error:', error.response.data);
            } else if (error.request) {
                console.error('No response received:', error.request);
            } else {
                console.error('Error setting up request:', error.message);
            }
        }
    };

    return (
        <AuthContext.Provider value={{ user, register, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
