import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await login({ email, password });
            navigate('/dashboard'); // Redirect to the dashboard page after successful login
        } catch (error) {
            console.error('Login failed:', error);
            // Optionally handle error and show a message to the user
        }
    };

    return (
        <div className="card mx-auto mt-5" style={{ maxWidth: '400px', backgroundColor: '#f8f9fa' }}>
            <div className="card-body">
                <h2 className="card-title text-center mb-4" style={{fontSize:'26px'}}>Login to continue</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control"
                            placeholder="Email"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="form-control"
                            placeholder="Password"
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary btn-block offset-sm-5">Login</button>
                </form>
            </div>
        </div>
    );
};

export default Login;
