import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [address, setAddress] = useState('');
    const [gender, setGender] = useState('');
    const [qualification, setQualification] = useState('');
    const [role, setRole] = useState('');

    const { register } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await register({ name, email, password, mobileNumber, address, gender, qualification, role });
            navigate('/login'); 
        } catch (error) {
            console.error('Registration failed:', error);
        }
    };

    return (
        <div className="card mx-auto mt-5" style={{ maxWidth: '590px', backgroundColor: '#f8f9fa' }}>
            <div className="card-body">
                <h2 className="card-title text-center mb-4">Register</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input
                            type="text"
                            id="name"
                            className="form-control"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter your name"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input
                            type="email"
                            id="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Password</label>
                        <input
                            type="password"
                            id="password"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter your password"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="mobileNumber" className="form-label">Mobile Number</label>
                        <input
                            type="tel"
                            id="mobileNumber"
                            className="form-control"
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                            placeholder="Enter your mobile number"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="address" className="form-label">Address</label>
                        <select
                            id="address"
                            className="form-control"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            required
                        >
                            <option value="">Select your address</option>
                            <option value="address1">Kathmandu, Nepal</option>
                            <option value="address2">Pokhara, Nepal</option>
                            <option value="address3">Heatuda, Nepal</option>
                            <option value="address3">Baglung, Nepal</option>
                            <option value="address3">Nepalgunj, Nepal</option>
                            <option value="address3">Birgunj, Nepal</option>
                            <option value="address3">Dhangadi, Nepal</option>
                            <option value="address3">Dhading, Nepal</option>
                            <option value="address3">Surkhet, Nepal</option>
                            <option value="address3">Sindhupalchowk, Nepal</option>
                            <option value="address3">Jhapa, Nepal</option>
                            <option value="address3">Biratnagar, Nepal</option>
                            <option value="address3">Chitwan, Nepal</option>
                            <option value="address3">Nuwakot, Nepal</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="gender" className="form-label">Gender</label>
                        <select
                            id="gender"
                            className="form-control"
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            required
                        >
                            <option value="">Select your gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="qualification" className="form-label">Qualification</label>
                        <select
                            id="qualification"
                            className="form-control"
                            value={qualification}
                            onChange={(e) => setQualification(e.target.value)}
                            required
                        >
                            <option value="">Select your qualification</option>
                            <option value="qualification1">SEE</option>
                            <option value="qualification2">+2/A-Levels</option>
                            <option value="qualification3">Undergraduate</option>
                            <option value="qualification3">Postgraduate</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="role" className="form-label">Role</label>
                        <select
                            id="role"
                            className="form-control"
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            required
                        >
                            <option value="">Select your role</option>
                            <option value="admin">Admin</option>
                            <option value="instructor">Instructor</option>
                            <option value="student">Student</option>
                        </select>
                    </div>
                    <button type="submit" className="btn btn-primary btn-block">Register</button>
                </form>
            </div>
        </div>
    );
};

export default Register;
