// src/App.js
import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Register from './components/Register';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Course from './components/Course';
import Quiz from './components/Quiz';
import ViewScore from './components/ViewScore';
import { AuthProvider } from './context/AuthContext';
import axios from 'axios';

function App() {
    useEffect(() => {
        const fetchCsrfToken = async () => {
            // Wait for the document to be fully loaded
            await new Promise(resolve => {
                if (document.readyState === 'complete' || document.readyState === 'interactive') {
                    resolve();
                } else {
                    document.addEventListener('DOMContentLoaded', resolve);
                }
            });

            // Fetch CSRF token from meta tag
            const csrfToken = document.head.querySelector('meta[name="csrf-token"]');
            
            // Include CSRF token in Axios headers for all requests
            axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken?.content;
        };

        fetchCsrfToken();
    }, []);

    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path="/register" element={<Register />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/courses" element={<Course />} />
                    <Route path="/quizzes" element={<Quiz />} />
                    <Route path="/viewscores" element={<ViewScore />} />
                </Routes>
            </Router>
        </AuthProvider>
    );
}

export default App;
